import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Logo from '../components/Logo'
import Header from '../components/Header'
import ModalContainer from '../components/ModalContainer'
import YoutubeEmbed from '../components/YoutubeEmbed'
import YoutubeMultiEmbed from '../components/YoutubeMultiEmbed'
// import ShowAds from '../containers/showAds'
import Footer from '../components/Footer'

const Index = ({ 
  data: { 
    banner, 
    mobilebanner, 
    logo, 
    logohover, 
    heroBanner, 
    vasarely, 
    vasarelySmall 
  }, 
  pageContext: { 
    locale, 
    slug,
    content, 
    alternates,
  }, 
  location 
}) => {
  
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  
  const banners = {
    desktop: banner.edges[0].node.original.src,
    mobile: mobilebanner.edges[0].node.original.src,
    hero: heroBanner.edges[0].node.original.src,
  }
  
  return (
    <React.Fragment>
      <SEO 
        pathname={location.pathname} 
        locale={locale} 
        banner={banners.desktop}
        slug={slug}
      />
      <Header 
        slug={slug} 
        locale={locale} 
        location={location}
      />
      <MainWrapper>
        <section
          className="intro desktop"
          style={{
            paddingTop: '80px',
            background: `url(${banners.mobile}) center no-repeat`,
            backgroundSize: 'cover',
          }}
        ></section>

        <section
          className="intro mobile auto-height"
          style={{ paddingTop: "5rem" }}
        >
          <img src={banners.mobile} style={{ width: '100%' }} alt="David Jarre, Magic From Home" />
        </section>
        
        {/* <section className="content intro-ads desktop" style={{ 
            padding: "8rem 0 3rem",
            background: `url(${banners.desktop}) bottom no-repeat`, 
            backgroundSize: 'cover',
          }}
        >
          <div className="container" style={{ borderRadius: ".3rem", padding: 0 }}>
            <ShowAds>
              <div>
                <img src={banners.hero} alt="David Jarre Mosaic" />
              </div>
              <div style={{ textAlign: "center" }}>
                <h1>
                  <small>Venez découvrir mon one-man show</small><br />
                  <strong>"Mosaic"</strong> au <strong>Théâtre de Paris</strong><br />
                  le <strong>11</strong> et le <strong>18 décembre 2023</strong>
                </h1>
                <a href="https://www.theatredeparis.com/evenement/david-jarre/" target="_blank" rel="noreferrer" className="cta white">
                  Réserver
                </a>
              </div>
            </ShowAds>
          </div>
        </section> */}

        {/* <section className="content intro-ads mobile" style={{
          padding: "5rem 0 0",
          background: `url(${banners.mobile}) bottom right no-repeat`,
          backgroundSize: 'cover',
        }}
        >
          <div className="container" style={{ borderRadius: ".3rem", padding: 0 }}>
            <ShowAds>
              <div>
                <img src={banners.hero} alt="David Jarre Mosaic" />
              </div>
              <div>
                <h1>
                  <small>Venez découvrir mon one-man show</small><br />
                  <strong>"Mosaic"</strong> au <strong>Théâtre de Paris</strong><br />
                  le <strong>11</strong> et le <strong>18 décembre 2023</strong>
                </h1>
                <a href="https://www.theatredeparis.com/evenement/david-jarre/" target="_blank" rel="noreferrer" className="cta white">
                  Réserver
                </a>
              </div>
            </ShowAds>
          </div>
        </section> */}

        <section className="content about" id="about">
          <div className="container">
            <div className="headline" style={{ marginBottom: "3rem" }}>
              <p>"You wonder when he will arrive, and you wonder even more when he leaves..."</p>
              <span className="credits">Vanity Fair</span>
            </div>
            
            <YoutubeEmbed embedId="uIFMoWaaE1s" />
          </div>
          <div className="container magicFromHome">
            <div className="row">
              <div className="pageIntro col nf np" style={{ marginTop: "5rem" }}>
                {
                  content.map((item, index_) => {
                    return <p key={`about-paragraph-${index_}`}>{item}</p>
                  })
                }
                {/* <p>David Jarre est un artiste magicien franco-anglais, spécialisé dans la magie de proximité, dite close up, le mentalisme et la magie de scène.</p>
                <p>
                  Il est également connu pour ses apparitions à la télévision française, 
                  dans l'émission "Vivement Dimanche" animée par Michel Drucker. 
                  Il sera au <strong>Théâtre de Paris</strong> à partir de décembre 2023 avec <a href="https://www.theatredeparis.com/evenement/david-jarre/" target="_blank" rel="noreferrer">son premier spectacle <strong>"Mosaic"</strong></a>.
                </p> */}
              </div>
            </div>
          </div>

          <div>
            <h2 style={{ textAlign: "center", marginTop: "5rem" }}>Plus de vidéos</h2>
            <YoutubeMultiEmbed videos={[
              'wNj9Dhox5Us', 
              'QtArWSHjBRI', 
              'rBugIMz7tQ4', 
              '69wnoLOE1SI',
            ]} />
          </div>
          <Logo 
            logo={logo.edges[0].node.publicURL}
            logohover={logohover.edges[0].node.publicURL}
          />
          <ModalContainer 
            triggerText={i18n.buttons[0]} 
            locale={locale}
            button={true}
          />
        </section>
      </MainWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Index
export const pageQuery = graphql`
  query Index {
    heroBanner: allImageSharp(filter: {original: {src: {regex: "/IMG_9149/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    banner: allImageSharp(filter: {original: {src: {regex: "/david-jarre-header-desktop/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/david-jarre-header-mobile/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    vasarely: allFile(filter: {relativePath: {eq: "vasarely.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    vasarelySmall: allFile(filter: {relativePath: {eq: "vasarely-small.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`