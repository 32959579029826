import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'

const YoutubeWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  > div {
    width: 50%;
    padding: .5rem;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
      padding: 10px 15px;
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

const YoutubeMultiEmbed = ({ videos }) => {
  return (
    <YoutubeWrapper>
      {
        videos.length > 0 && videos.map((item, index) => {
          return (
            <div key={`video-${index}`}>
              <VideoContainer>
                <iframe
                  src={`https://www.youtube.com/embed/${item}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`David Jarre - Vivement dimanche - ${index}`}
                />
              </VideoContainer>
            </div>
          )
        })
      }
    </YoutubeWrapper>
  )
};

YoutubeMultiEmbed.propTypes = {
  videos: PropTypes.array.isRequired
};

export default YoutubeMultiEmbed;